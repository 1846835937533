import { ref, computed } from "vue";

export const isNewVariable = (_v) => !_v.id;

export const VAR_TYPES = {
  JSON: "json",
  TXT: "text",
};

export class Variable {
  constructor({ name, value, type = VAR_TYPES.TXT, id = "" }) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.type = type;
  }
}

export function useVariable(variable) {
  const _variableName = ref(variable.name);
  const _variableValue = ref(variable.value);
  const _variableType = ref(variable.type);
  const isDirty = ref(false);

  const variableName = computed({
    get() {
      return _variableName.value;
    },
    set(val) {
      if (val === _variableName.value) {
        return;
      }
      isDirty.value = true;
      _variableName.value = val;
    }
  });

  const variableValue = computed({
    get() {
      return _variableValue.value;
    },
    set(val) {
      if (val === _variableValue.value) {
        return;
      }
      isDirty.value = true;
      _variableValue.value = val;
    }
  });

  const variableType = computed({
    get() {
      return _variableType.value;
    },
    set(val) {
      if (val === _variableType.value) {
        return;
      }
      isDirty.value = true;
      _variableType.value = val;
    }
  });

  const isJsonVariable = computed(() => variableType.value === VAR_TYPES.JSON);

  const getUpdatedVariable = () => {
    // eslint-disable-next-line no-undef
    const _v = new Variable(structuredClone(variable));
    _v.name = variableName.value;
    _v.value = variableValue.value;
    _v.type = variableType.value;
    if (_v.id === "") {
      delete _v.id;
    }
    return _v;
  };

  const isValidName = (name) => name && name.length > 0;
  const isValidVariable = (_v) => isValidName(_v.name)

  const variableNameRules = [
    name => isValidName(name),
    name => name.length <= 255,
  ];

  return {
    isValidVariable,
    variableName,
    variableValue,
    variableType,
    isJsonVariable,
    getUpdatedVariable,
    variableNameRules,
    isDirty,
  }
}
